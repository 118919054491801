
import { defineComponent, ref, toRefs, SetupContext, onMounted } from 'vue';
export default defineComponent({
  props: {
    className: {
      type: [String, Array, Object],
      default: '',
    },
    isDismissable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close', 'open'],
  setup: (props, { emit }: SetupContext) => {
    const isOpen = ref<boolean>(false);
    const { isDismissable } = toRefs(props);

    onMounted(() =>
      window.document.addEventListener('keydown', closeEscapeKey),
    );

    const clickedModal = (event: any) => {
      if (isDismissable.value) {
        if (event.target.matches('.modal')) {
          close();
        }
      }
    };

    const closeEscapeKey = (event: any) => {
      if (isDismissable.value) {
        if ((event.charCode || event.keyCode) === 27) {
          close();
        }
      }
    };

    const close = () => {
      isOpen.value = false;
      emit('close');
    };

    const open = () => {
      isOpen.value = true;
      emit('open');
    };

    return { isOpen, open, close, clickedModal };
  },
});
